define('liftwedstrijd-berlijn/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel() {
      this.transitionTo('berlijn.intro');
    }
  });
});