define('liftwedstrijd-berlijn/router', ['exports', 'liftwedstrijd-berlijn/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('berlijn', function () {
      this.route('programma');
      this.route('intro');
      this.route('route');
      this.route('bordjes');
      this.route('tips');
      this.route('contact');
      this.route('veiligheid');
      this.route('spel');
    });
  });

  exports.default = Router;
});