define('liftwedstrijd-berlijn/controllers/berlijn', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    options: ['Intro', 'Programma', 'Spel', 'Route', 'Bordjes', 'Tips', 'Veiligheid', 'Contact'],
    rightSideBarOpen: false,

    actions: {
      toggle(propName) {
        this.toggleProperty(propName);
      },

      openSomething(item) {
        this.send(`${item}`);
      },

      Intro() {
        this.transitionToRoute('berlijn.intro');
      },

      Programma() {
        this.transitionToRoute('berlijn.programma');
      },

      Spel() {
        this.transitionToRoute('berlijn.spel');
      },

      Route() {
        this.transitionToRoute('berlijn.route');
      },

      Bordjes() {
        this.transitionToRoute('berlijn.bordjes');
      },

      Tips() {
        this.transitionToRoute('berlijn.tips');
      },

      Veiligheid() {
        this.transitionToRoute('berlijn.veiligheid');
      },

      Contact() {
        this.transitionToRoute('berlijn.contact');
      }
    }
  });
});