define('liftwedstrijd-berlijn/services/google-maps-api', ['exports', 'ember-google-maps/services/google-maps-api'], function (exports, _googleMapsApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _googleMapsApi.default;
    }
  });
});